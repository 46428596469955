import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AdminDashboard() {
  return (
    <>
      <Helmet>
        <title>Panel de Control - {website_name}</title>
      </Helmet>

      <div className="admin-header">
        <div className="flex">
          <div className="title">Panel de Control</div>
        </div>
        <div className="flex">
          <button type="button" className="button icon-button">
            <FontAwesomeIcon />
          </button>
        </div>
      </div>
      
      <div className="admin-body"></div>
    </>
  );
}
export default AdminDashboard;
