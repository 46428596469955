import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";

import ProductCard from "../common/ProductCard";
import ProductCardLoad from "../common/ProductCardLoad";
import { website_name } from "../../database/config";

function Category() {
  const { t } = useTranslation();

  const { category_path } = useParams();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsRef = collection(db, "products");

    getDocs(productsRef)
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setProducts(data);
      })

      .catch((error) => {
        console.log("Error al obtener los datos: ", error);
      });
  }, []);

  const productSelected = products.find(
    (product) => product.category_path === category_path
  );

  const sameCategoryProducts = products.filter(
    (product) => product.category_path === productSelected.category_path
  );

  return (
    <>
      <Helmet>
        <title>
          {website_name} - {t("pages.store.title")}
        </title>
        <meta property="og:url" content="https://danielospid.com/store" />
      </Helmet>

      <section className="section">
        <div className="container">
          {productSelected && productSelected.category_title && (
            <div className="row-title">
              <h2>{productSelected.category_title}</h2>
            </div>
          )}
          <div className="grid">
            {sameCategoryProducts.length === 0 ? (
              <>
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
              </>
            ) : (
              sameCategoryProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  show_product={product.show_product}
                  show_dto={product.show_dto}
                  show_sot={product.show_sot}
                  img_def={product.img_def}
                  img_hov={product.img_hov}
                  title={product.title}
                  price={product.price}
                  until={product.until}
                  link={product.link}
                  path={product.path}
                />
              ))
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default Category;
