import { Link } from "react-router-dom";

function PhotoCard(props) {
  return (
    <>
      {props.show && (
        <div className="photo-card">
          <Link to={`/photos/${props.id}`}>
            <img
              alt={props.title}
              src={props.image}
              title={props.title}
              className="photo-image"
            />
            <div className="photo-screen"></div>
          </Link>
        </div>
      )}
    </>
  );
}
export default PhotoCard;
