import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faCalendarDays,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth, useMusic, useProducts } from "../../firebase/fbQueries";
import NoFound from "./NoFound";
import { website_name } from "../../database/config";
import { useState } from "react";

function Product() {
  const { t } = useTranslation();
  const { path } = useParams();
  const music = useMusic(db);
  const products = useProducts(db);
  const productSelected = products.find((product) => product.path === path);
  const songs = music.find((list) => list.path === productSelected.songs);
  const htmlStringDesc = productSelected ? productSelected.desc : "";
  const htmlStringInfo = productSelected ? productSelected.info : "";
  let link = productSelected ? productSelected.link : "";
  if (link && link.startsWith("https://store.danielospid.com/products/")) {
    link = link.substring("https://store.danielospid.com/products/".length);
  }
  const authUser = useAuth(db);

  const [photoSelected, setPhotoSelected] = useState(null);

  const changePhoto = (photo, index) => {
    setPhotoSelected(photo);
    setColor(index);
  };

  const [size, setSize] = useState(0);

  const sizeSelected = (id) => {
    setSize(id);
  };

  const [color, setColor] = useState(0);

  const colorSelected = (id) => {
    setColor(id);
    setPhotoSelected(productSelected.gallery[id]);
  };

  const [price, setPrice] = useState(null);
  const [priceSelected, setPriceSelected] = useState(0);

  const changePrice = (price, index) => {
    setPrice(price);
    setPriceSelected(index);
  };

  return (
    <>
      {productSelected ? (
        productSelected.show_product || authUser ? (
          <div className="section">
            <Helmet>
              <title>
                {productSelected.title} - {website_name}
              </title>
              <meta name="description" content={productSelected.desc} />
              <meta property="og:title" content={productSelected.title} />
              <meta property="og:description" content={productSelected.desc} />
              <meta
                property="og:url"
                content={
                  "https://danielospid.com/products/" + productSelected.path
                }
              />
              <meta property="og:image" content={productSelected.img_def} />
              <meta property="twitter:card" content="summary" />
              <meta property="twitter:title" content={productSelected.title} />
              <meta
                property="twitter:description"
                content={productSelected.desc}
              />
              <meta
                property="twitter:image"
                content={productSelected.img_def}
              />
            </Helmet>
            <div className="container">
              <div className="product">
                <div className="product-pictures">
                  <div className="product-pictures-container">
                    {!photoSelected && (
                      <img
                        src={productSelected.img_def}
                        alt={productSelected.title}
                        className="product-pictures-image"
                      />
                    )}
                    {photoSelected && (
                      <img
                        src={photoSelected}
                        alt={productSelected.title}
                        className="product-pictures-image"
                      />
                    )}
                  </div>
                  {productSelected.gallery && (
                    <div className="product-gallery">
                      {productSelected.gallery.map((photo, index) => (
                        <div
                          className="product-photo"
                          key={index}
                          onClick={() => changePhoto(photo, index)}
                        >
                          <img src={photo} alt={productSelected.title} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="product-details">
                  {/* title & price */}
                  <div className="product-details-column">
                    {/* title */}
                    <h2>{productSelected.title}</h2>
                    {/* price */}
                    <div className="product-details-price">
                      {productSelected.show_dto ? (
                        <>
                          <span className="price-new">
                            {"$"}
                            {productSelected.promo_price.toFixed(2)}
                            {" USD"}
                          </span>
                          <span className="price-old">
                            {"$"}
                            {productSelected.price.toFixed(2)}
                            {" USD"}
                          </span>
                        </>
                      ) : (
                        <>
                          {!price && (
                            <span className="price">
                              {"$"}
                              {productSelected.price.toFixed(2)}
                              {" USD"}
                            </span>
                          )}
                          {price && (
                            <span className="price">
                              {"$"}
                              {price.toFixed(2)}
                              {" USD"}
                            </span>
                          )}
                        </>
                      )}
                      {/* {productSelected.until && (
                        <span className="price">
                          {"- $"}
                          {productSelected.until.toFixed(2)}
                          {" USD"}
                        </span>
                      )} */}
                      <div className="row-tags">
                        {productSelected.show_pre && (
                          <span className="pre">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            {t("store.product.pre")}
                          </span>
                        )}
                        {productSelected.show_dto && (
                          <span className="dto">
                            <FontAwesomeIcon icon={faPercent} />
                            {t("store.product.dto")}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {productSelected.prices && (
                    <div className="product-details-column">
                      <b>Precios</b>
                      <div className="product-details-row">
                        {productSelected.prices.map((item, index) => (
                          <>
                            <input
                              key={index}
                              type="button"
                              value={`$${item.toFixed(2)}`}
                              className={
                                priceSelected === index
                                  ? "button option-button selected"
                                  : "button option-button"
                              }
                              onClick={() => changePrice(item, index)}
                            />
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* btn */}
                  <div className="product-details-column">
                    {productSelected.show_sot && (
                      <button
                        type="button"
                        className="button primary-button"
                        disabled
                      >
                        {t("store.product.button.sold_out")}
                      </button>
                    )}
                    {!productSelected.show_sot && (
                      <a
                        href={"https://store.danielospid.com/products/" + link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button primary-button"
                      >
                        <span>{t("store.product.button.see_in_store")}</span>
                        <span className="icon">
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </span>
                      </a>
                    )}
                  </div>
                  {productSelected.sizes && (
                    <div className="product-details-column">
                      <b>Tallas</b>
                      <div className="product-details-row">
                        {productSelected.sizes.map((item, index) => (
                          <>
                            <input
                              key={index}
                              type="button"
                              value={item}
                              className={
                                size === index
                                  ? "button option-button selected"
                                  : "button option-button"
                              }
                              onClick={() => sizeSelected(index)}
                            />
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                  {productSelected.colors && (
                    <div className="product-details-column">
                      <b>Colores</b>
                      <div className="product-details-row">
                        {productSelected.colors.map((item, index) => (
                          <>
                            <input
                              key={index}
                              type="button"
                              value={item}
                              className={
                                color === index
                                  ? "button option-button selected"
                                  : "button option-button"
                              }
                              onClick={() => colorSelected(index)}
                            />
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* songs */}
                  {productSelected.songs && (
                    <div className="product-details-column">
                      <b>{t("store.product.songs")}</b>
                      <ol>
                        {songs.songs.map((song, index) => (
                          <li key={index}>{song}</li>
                        ))}
                      </ol>
                    </div>
                  )}
                  {/* desc */}
                  {productSelected.desc && (
                    <div className="product-details-column">
                      <b>{t("store.product.desc")}</b>
                      <p
                        dangerouslySetInnerHTML={{ __html: htmlStringDesc }}
                      ></p>
                    </div>
                  )}
                  {/* info */}
                  {productSelected.info && (
                    <div className="product-details-column">
                      <b>{t("store.product.info")}</b>
                      <p
                        dangerouslySetInnerHTML={{ __html: htmlStringInfo }}
                      ></p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NoFound />
        )
      ) : (
        <div className="product-load">{t("store.product.loading")}</div>
      )}
    </>
  );
}
export default Product;
