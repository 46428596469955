import { useState } from "react";
import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import {
  faAngleDown,
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth, useProducts } from "../../firebase/fbQueries";
import ProductCard from "../common/ProductCard";
import ProductCardLoad from "../common/ProductCardLoad";
import { website_name } from "../../database/config";

function Store() {
  const authUser = useAuth(db);
  const { t } = useTranslation();
  const products = useProducts(db);
  
  const [selectedCategory, setSelectedCategory] = useState("all");
  
  const sameCategoryProducts = products.filter(
    (product) =>
      selectedCategory === "all" || product.category.includes(selectedCategory)
  );
  
  const [searchTerm, setSearchTerm] = useState("");
  
  const sameTitleProducts = sameCategoryProducts.filter((product) => {
    const matchesSearch = product.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  const showCategory = products
    .filter((product) => product.show_product || authUser)
    .flatMap((product) => product.category);

  return (
    <>
      <Helmet>
        <title>
          {t("store.title")} - {website_name}
        </title>
        <meta property="og:url" content="https://danielospid.com/store" />
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>{t("store.title")}</h2>
            <div className="flex-action">
              <div className="select-wrapper">
                <input
                  type="text"
                  placeholder="Buscar por título..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} size="sm" color="#757575" />
              </div>
              <div className="select-wrapper">
                <select
                  name="selection of order"
                  title="Ordenar por categoría"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="all" selected disabled>
                    {t("store.select.order-by-category")}
                  </option>
                  <option value="all">{t("store.select.all")}</option>
                  {showCategory.includes("music") && (
                    <optgroup label="Productos de Música">
                      {showCategory.includes("music") && (
                        <option value="music">{t("store.select.music")}</option>
                      )}
                      {showCategory.includes("cd") && (
                        <option value="cd">{t("store.select.cd")}</option>
                      )}
                      {showCategory.includes("vinilo") && (
                        <option value="vinilo">
                          {t("store.select.vinilo")}
                        </option>
                      )}
                      {showCategory.includes("casete") && (
                        <option value="casete">
                          {t("store.select.casete")}
                        </option>
                      )}
                      {showCategory.includes("digital") && (
                        <option value="digital">
                          {t("store.select.digital")}
                        </option>
                      )}
                    </optgroup>
                  )}
                  {showCategory.includes("merch") && (
                    <optgroup label="Productos de Ropa">
                      {showCategory.includes("merch") && (
                        <option value="merch">{t("store.select.merch")}</option>
                      )}
                      {showCategory.includes("shirt") && (
                        <option value="shirt">{t("store.select.shirt")}</option>
                      )}
                      {showCategory.includes("hoodie") && (
                        <option value="hoodie">
                          {t("store.select.hoodie")}
                        </option>
                      )}
                      {showCategory.includes("shorts") && (
                        <option value="shorts">
                          {t("store.select.shorts")}
                        </option>
                      )}
                      {showCategory.includes("pants") && (
                        <option value="pants">{t("store.select.pants")}</option>
                      )}
                    </optgroup>
                  )}
                  {showCategory.includes("gift-cards") && (
                    <optgroup label="Otros Productos">
                      {showCategory.includes("gift-cards") && (
                        <option value="gift-cards">
                          {t("store.select.gift-cards")}
                        </option>
                      )}
                    </optgroup>
                  )}
                </select>
                <FontAwesomeIcon icon={faAngleDown} id="iconDown" />
                <FontAwesomeIcon icon={faFilter} id="iconFilter" />
              </div>
            </div>
          </div>
          <div className="grid">
            {sameTitleProducts.length === 0 ? (
              <>
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
                <ProductCardLoad />
              </>
            ) : (
              sameTitleProducts
                .sort((a, b) => a.id - b.id)
                .map((product) => (
                  <ProductCard
                    key={product.id}
                    show_product={product.show_product}
                    show_dto={product.show_dto}
                    show_pre={product.show_pre}
                    show_sot={product.show_sot}
                    img_def={product.img_def}
                    img_hov={product.img_hov}
                    title={product.title}
                    price={product.price}
                    until={product.until}
                    link={product.link}
                    path={product.path}
                  />
                ))
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Store;
