import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { website_name } from "../../database/config";

function Photo() {
  const { id } = useParams();

  const { t } = useTranslation();

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const photosRef = collection(db, "photos");

    getDocs(photosRef)
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setPhotos(data);
      })

      .catch((error) => {
        console.log("Error al obtener los datos: ", error);
      });
  }, []);

  const photoSelected = photos.find((photo) => photo.id === id);

  return (
    <>
      <Helmet>
        <title>
          {website_name} - {t("photos.title")}
        </title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div id="loading">
            <span className="icon-loading">
              <FontAwesomeIcon icon={faSpinner} />
            </span>
          </div>
        </div>
      </section>

      {photoSelected ? (
        <div className="image-wrapper">
          <div className="image-wrapper-header">
            <div className="container">
              <div className="flex">
                {photoSelected.date && (
                  <span className="image-wrapper-date">
                    {new Date(
                      photoSelected.date.seconds * 1000
                    ).toLocaleDateString("es", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                )}
              </div>
              <div className="flex">
                <button
                  type="button"
                  className="button icon-button"
                  title="Cerrar foto"
                  aria-label="Cerrar foto"
                >
                  <Link to="/photos">
                    <FontAwesomeIcon icon={faClose} size="2xl" />
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="image-wrapper-content">
            {photoSelected ? (
              <img
                src={photoSelected.image}
                alt={photoSelected.alt}
                className="wrapped-image"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
export default Photo;
