import Newsletter from "./Newsletter";
import Socials from "./Socials";
import Copyright from "./Copyright";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <Newsletter />
        <Socials />
        <hr />
        <Copyright handleCookies={props.handleCookies} />
      </div>
    </footer>
  );
}

export default Footer;
