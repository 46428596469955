import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

function Cookies(props) {
  const [cookiesCount, setCookiesCount] = useState(1);
  const [allCookies, setAllCookies] = useState(false);
  const [necessary, setNecessary] = useState(true);
  const [performance, setPerformance] = useState(false);
  const [functionality, setFunctionality] = useState(false);
  const [publicity, setPublicity] = useState(false);

  useEffect(() => {
    if (performance && functionality && publicity) {
      setAllCookies(true);
    } else {
      setAllCookies(false);
    }
  }, [performance, functionality, publicity]);

  useEffect(() => {
    const performanceCookies =
      localStorage.getItem("performanceCookies") === "true";
    setPerformance(performanceCookies);

    const functionalityCookies =
      localStorage.getItem("functionalityCookies") === "true";
    setFunctionality(functionalityCookies);

    const publicityCookies =
      localStorage.getItem("publicityCookies") === "true";
    setPublicity(publicityCookies);
  }, []);

  useEffect(() => {
    localStorage.setItem("performanceCookies", performance.toString());
  }, [performance]);

  useEffect(() => {
    localStorage.setItem("functionalityCookies", functionality.toString());
  }, [functionality]);

  useEffect(() => {
    localStorage.setItem("publicityCookies", publicity.toString());
  }, [publicity]);

  const handleAllCookies = () => {
    if (!allCookies) {
      setCookiesCount(4);
      setPerformance(true);
      setFunctionality(true);
      setPublicity(true);
      setAllCookies(true);
    } else {
      setCookiesCount(1);
      setPerformance(false);
      setFunctionality(false);
      setPublicity(false);
      setAllCookies(false);
    }
  };

  const handlePerformance = () => {
    if (performance) {
      setCookiesCount(cookiesCount - 1);
      setPerformance(false);
    } else {
      setCookiesCount(cookiesCount + 1);
      setPerformance(true);
    }
  };

  const handleFunctionality = () => {
    if (functionality) {
      setCookiesCount(cookiesCount - 1);
      setFunctionality(false);
    } else {
      setCookiesCount(cookiesCount + 1);
      setFunctionality(true);
    }
  };

  const handlePublicity = () => {
    if (publicity) {
      setCookiesCount(cookiesCount - 1);
      setPublicity(false);
    } else {
      setCookiesCount(cookiesCount + 1);
      setPublicity(true);
    }
  };

  const handleNoCookies = () => {
    if (allCookies === true) {
      setCookiesCount(1);
      setPerformance(false);
      setFunctionality(false);
      setPublicity(false);
      setAllCookies(false);
    }
  };

  return (
    <div className={!props.cookies ? "cookies" : "cookies show"}>
      <div className="container">
        <div className="cookies-header">
          <div className="cookies-flex">
            <div className="cookies-title">Categorias</div>
          </div>
          <div className="cookies-flex">
            <div className="cookies-title">
              {cookiesCount} de 4 permitidas
              <span
                className="cookies-button"
                role="button"
                onClick={handleAllCookies}
                title={`Todas las Cookies: ${
                  !allCookies ? "Desactivadas" : "Activadas"
                }`}
                aria-label={`Todas las Cookies: ${
                  !allCookies ? "Desactivadas" : "Activadas"
                }`}
              >
                <FontAwesomeIcon
                  icon={!allCookies ? faToggleOff : faToggleOn}
                  size="lg"
                />
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="cookies-content">
          {/* Cookies Necesarias */}
          <div className="cookies-box">
            <div className="cookies-box-data">
              <div className="cookies-box-title">Cookies Necesarias</div>
              <div className="cookies-box-info">
                Estas cookies son esenciales para que el sitio web funcione
                correctamente. Permiten la navegación por el sitio y el uso de
                sus funcionalidades básicas, como acceder a áreas seguras del
                sitio.
              </div>
            </div>
            <div className="cookies-box-control">
              <button
                type="button"
                className="button icon"
                style={{ cursor: "no-drop" }}
                title="Cookies Necesarias: Activadas"
              >
                <FontAwesomeIcon
                  icon={!necessary ? faToggleOff : faToggleOn}
                  size="xl"
                />
              </button>
            </div>
          </div>
          {/* Cookies de Rendimiento */}
          <div className="cookies-box">
            <div className="cookies-box-data">
              <div className="cookies-box-title">Cookies de Rendimiento</div>
              <div className="cookies-box-info">
                Estas cookies recopilan información sobre cómo los visitantes
                utilizan el sitio web, por ejemplo, qué páginas visitan con más
                frecuencia y si reciben mensajes de error de las páginas web. No
                recopilan información que identifique al visitante; toda la
                información recopilada es anónima y se utiliza únicamente para
                mejorar el funcionamiento del sitio.
              </div>
            </div>
            <div className="cookies-box-conrol">
              <button
                type="button"
                className="button icon"
                onClick={handlePerformance}
                title={`Cookies de Rendimiento: ${
                  !performance ? "Desactivadas" : "Activadas"
                }`}
                aria-label={`Cookies de Rendimiento: ${
                  !performance ? "Desactivadas" : "Activadas"
                }`}
              >
                <FontAwesomeIcon
                  icon={!performance ? faToggleOff : faToggleOn}
                  size="xl"
                />
              </button>
            </div>
          </div>
          {/* Cookies de Funcionalidad */}
          <div className="cookies-box">
            <div className="cookies-box-data">
              <div className="cookies-box-title">Cookies de Funcionalidad</div>
              <div className="cookies-box-info">
                Estas cookies permiten que el sitio web recuerde las elecciones
                que usted hace (como su nombre de usuario, idioma o la región en
                la que se encuentra) y proporcionan características mejoradas y
                más personales. Por ejemplo, pueden recordar sus preferencias de
                personalización del sitio.
              </div>
            </div>
            <div className="cookies-box-control">
              <button
                type="button"
                className="button icon"
                onClick={handleFunctionality}
                title={`Cookies de Funcionalidad: ${
                  !functionality ? "Desactivadas" : "Activadas"
                }`}
                aria-label={`Cookies de Funcionalidad: ${
                  !functionality ? "Desactivadas" : "Activadas"
                }`}
              >
                <FontAwesomeIcon
                  icon={!functionality ? faToggleOff : faToggleOn}
                  size="xl"
                />
              </button>
            </div>
          </div>
          {/* Cookies de Publicidad */}
          <div className="cookies-box">
            <div className="cookies-box-data">
              <div className="cookies-box-title">Cookies de Publicidad</div>
              <div className="cookies-box-info">
                Estas cookies se utilizan para entregar anuncios más relevantes
                para usted y sus intereses. También se utilizan para limitar el
                número de veces que usted ve un anuncio y para ayudar a medir la
                efectividad de la campaña publicitaria. Recuerdan que usted ha
                visitado un sitio web y esta información se comparte con otras
                organizaciones, como anunciantes.
              </div>
            </div>
            <div className="cookies-box-control">
              <button
                type="button"
                className="button icon"
                onClick={handlePublicity}
                title={`Cookies de Publicidad: ${
                  !publicity ? "Desactivadas" : "Activadas"
                }`}
                aria-label={`Cookies de Rendimiento: ${
                  !publicity ? "Desactivadas" : "Activadas"
                }`}
              >
                <FontAwesomeIcon
                  icon={!publicity ? faToggleOff : faToggleOn}
                  size="xl"
                />
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div className="cookies-action">
          <button
            type="button"
            className={
              allCookies
                ? "button primary-button show"
                : "button primary-button"
            }
            onClick={!allCookies ? handleAllCookies : handleAllCookies}
          >
            {!allCookies ? "Aceptar Todas" : "Guardar Preferencias"}
          </button>
          {allCookies === true && (
            <button
              type="button"
              className="button primary-button"
              onClick={handleNoCookies}
            >
              Cancelar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default Cookies;
