import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
// import { useMusic } from "../../firebase/fbQueries";
import { db, storage } from "../../firebase/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  faAdd,
  faAngleDown,
  faBug,
  faCloudArrowUp,
  faRecordVinyl,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
// import AdminMusicBox from "./AdminMusicBox";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  faAmazon,
  faApple,
  faDeezer,
  faSoundcloud,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link, Outlet } from "react-router-dom";

function Music() {
  // const music = useMusic(db);
  const [songs, setSongs] = useState([""]);
  const [covers, setCovers] = useState([""]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedMusic, setSelectedMusic] = useState(null);

  const { register, handleSubmit, setValue, reset } = useForm();

  // const handleForm = () => {
  //   setOpenForm(!openForm);
  // };

  const resetForm = () => {
    setSelectedMusic(null);
    reset();
    setSongs([""]);
    setCovers([""]);
  };

  //   const deleteMusic = async (id) => {
  //     const docRef = doc(db, "music", id);

  //     try {
  //       await deleteDoc(docRef);
  //       console.log("Document successfully deleted!");
  //     } catch (error) {
  //       console.error("Error removing document: ", error);
  //     }
  //   };

  const onSubmit = async (data) => {
    const { title, path, type } = data;
    const musicData = {
      title,
      path,
      type,
      songs,
      covers,
      created: new Date(),
    };

    try {
      const newDoc = doc(db, "music", title.replace(/\s+/g, "-").toLowerCase());
      await setDoc(newDoc, musicData);
      console.log("Lanzamiento creado exitosamente");
    } catch (error) {
      console.error("Error al crear el lanzamiento:", error);
    }
  };

  const handleDelete = async () => {
    if (selectedMusic) {
      try {
        const musicDoc = doc(db, "music", selectedMusic.id);
        await deleteDoc(musicDoc);
        console.log("Lanzamiento eliminado exitosamente");
        resetForm();
        setOpenForm(false);
      } catch (error) {
        console.error("Error al eliminar el lanzamiento:", error);
      }
    }
  };

  const handleAddCover = () => {
    setCovers([...covers, ""]);
  };

  const handleCoverChange = (index, value) => {
    const newCovers = [...covers];
    newCovers[index] = value;
    setCovers(newCovers);
  };

  const handleRemoveCover = (indexToRemove) => {
    setCovers((prevCovers) =>
      prevCovers.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleAddSong = () => {
    setSongs([...songs, ""]);
  };

  const handleSongChange = (index, value) => {
    const newSongs = [...songs];
    newSongs[index] = value;
    setSongs(newSongs);
  };

  const handleRemoveSong = (indexToRemove) => {
    setSongs((prevSongs) =>
      prevSongs.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleCoverFileChange = async (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `musicCovers/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      handleCoverChange(index, downloadURL);
      setValue(`covers[${index}]`, downloadURL);
    }
  };

  // const handleSelectMusic = (music) => {
  //   setSelectedMusic(music);
  //   setOpenForm(true);
  //   setValue("title", music.title);
  //   setValue("type", music.type);
  //   setValue("path", music.path);
  //   setSongs(music.songs);
  //   setCovers(music.covers);
  // };

  return (
    <>
      <Helmet>
        <title>Música - {website_name}</title>
      </Helmet>

      <div className="admin-header">
        <div className="flex">
          <div className="title">Música</div>
        </div>
        <div className="flex">
          <Link to="/admin/music">Inicio</Link> /
          <Link to="/admin/music/create">Crear</Link> /
          <Link to="/admin/music/update">Actualizar</Link> /
          <Link to="/admin/music/delete">Eliminar</Link>
        </div>
      </div>

      <div className="admin-body">
        <Outlet />
        {/* <hr /> */}
        {openForm && (
          <div className="admin-form">
            <div className="admin-form-header">
              <h3>
                {selectedMusic ? "Actualizar Lanzamiento" : "Nuevo Lanzamiento"}
              </h3>
            </div>
            <div className="admin-form-content">
              <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                  <h4>Componente de música</h4>
                  <div className="block">
                    {covers.map((cover, index) => (
                      <div key={index} className="block-item">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleCoverFileChange(e, index)}
                          id={`cover${index}`}
                          hidden
                        />
                        <button type="button" className="button icon-button">
                          <label htmlFor={`cover${index}`}>
                            <FontAwesomeIcon icon={faCloudArrowUp} size="xl" />
                          </label>
                        </button>
                        <input
                          type="url"
                          placeholder="Enlace de la portada"
                          value={cover}
                          {...register(`covers[${index}]`)}
                          onChange={(e) =>
                            handleCoverChange(index, e.target.value)
                          }
                        />
                        {covers.length > 1 && (
                          <button
                            type="button"
                            className="remove-button"
                            onClick={() => handleRemoveCover(index)}
                          >
                            <FontAwesomeIcon icon={faTrashCan} size="lg" />
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      type="button"
                      className="add-button"
                      onClick={handleAddCover}
                    >
                      <FontAwesomeIcon icon={faAdd} size="sm" />
                      Agregar portada
                    </button>
                  </div>
                  <div className="block">
                    <input
                      type="text"
                      placeholder="Título"
                      {...register("title", { required: true })}
                    />
                  </div>
                  <div className="block">
                    <input
                      type="text"
                      placeholder="Tipo"
                      {...register("type", { required: true })}
                    />
                  </div>
                  <div className="block">
                    <input
                      type="text"
                      placeholder="Enlace del lanzamiento"
                      {...register("path", { required: true })}
                    />
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <select {...register("show_music", { required: true })}>
                        <option value="true">Publico</option>
                        <option value="false">Privado</option>
                      </select>
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        className="down"
                        size="lg"
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <input type="datetime-local" {...register("date")} />
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <h4>Página de música</h4>
                  <div className="block">
                    <div className="column">
                      {songs.map((song, index) => (
                        <div className="block-item" key={index}>
                          {songs.length > 1 && (
                            <span className="count">{index + 1}.</span>
                          )}
                          <input
                            type="text"
                            placeholder="Nombre de la canción"
                            value={song}
                            onChange={(e) =>
                              handleSongChange(index, e.target.value)
                            }
                          />
                          {songs.length > 1 && (
                            <button
                              type="button"
                              className="remove-button"
                              onClick={() => handleRemoveSong(index)}
                            >
                              <FontAwesomeIcon icon={faTrashCan} size="lg" />
                            </button>
                          )}
                        </div>
                      ))}
                      <button
                        type="button"
                        className="add-button"
                        onClick={handleAddSong}
                      >
                        <FontAwesomeIcon icon={faAdd} size="sm" />
                        Agregar canción
                      </button>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="block">
                    <h4>Página de enlaces</h4>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faSpotify} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="Spotify"
                        {...register("spotify")}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faApple} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="Apple Music"
                        {...register("apple_music")}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faBug} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="Tidal"
                        {...register("tidal")}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faAmazon} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="Amazon Music"
                        {...register("amazon_music")}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faRecordVinyl} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="YouTube Music"
                        {...register("youtube_music")}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faDeezer} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="Deezer"
                        {...register("deezer")}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faSoundcloud} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="SoundCloud"
                        {...register("soundcloud")}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faBug} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="audiomack"
                        {...register("audiomack")}
                      />
                    </div>
                  </div>
                  <div className="block">
                    <div className="block-item">
                      <button type="button" className="button icon-button">
                        <FontAwesomeIcon icon={faYoutube} size="2xl" />
                      </button>
                      <input
                        type="url"
                        placeholder="YouTube"
                        {...register("youtube")}
                      />
                    </div>
                  </div>
                </fieldset>
                <div className="block">
                  <div className="row">
                    {selectedMusic ? (
                      <>
                        <button
                          type="reset"
                          className="button primary-button"
                          onClick={() => resetForm()}
                        >
                          Descartar
                        </button>
                        <button
                          type="button"
                          className="button primary-button"
                          onClick={() => handleDelete()}
                        >
                          Eliminar
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        className="button primary-button"
                        onClick={() => resetForm()}
                      >
                        Cancelar
                      </button>
                    )}
                    <button type="submit" className="button primary-button">
                      {selectedMusic ? "Actualizar" : "Publicar"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* <div className="admin-grid">
          {music.map((music, index) => (
            <AdminMusicBox
              key={index}
              {...music}
              music={music}
              handleSelectMusic={handleSelectMusic}
            />
          ))}
        </div> */}
      </div>
    </>
  );
}
export default Music;
