import NavMenu from "./NavMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useLinks } from "../../firebase/fbQueries";
import { useTranslation } from "react-i18next";
import { db } from "../../firebase/config";

function Menu(props) {
  const links = useLinks(db);
  const { t } = useTranslation();

  return (
    <div className={!props.menu ? "menu" : "menu show"}>
      {props.menu && (
        <div>
          <div className="menu-header">
            <div className="menu-title">{t("menu.title")}</div>
          </div>
          <div className="menu-navigator">
            <NavMenu click={props.handleMenu} />
            <nav className="nav-menu">
              <ul className="nav-list">
                {links
                  .sort((a, b) => a.order - b.order)
                  .map((item, index) => (
                    <>
                      {item.show_link && item.text && item.link && (
                        <li key={index} className="nav-item">
                          <a
                            href={
                              item.link.startsWith("http://")
                                ? item.link.replace("http://", "https://")
                                : item.link.startsWith("https://")
                                ? item.link
                                : "https://" + item.link
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link"
                          >
                            {item.text}
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} title="Nueva pestaña" />
                          </a>
                        </li>
                      )}
                    </>
                  ))}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
}
export default Menu;
