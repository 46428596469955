import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "../../firebase/config";
import { useVideos } from "../../firebase/fbQueries";
import { faEarthAmerica, faLock } from "@fortawesome/free-solid-svg-icons";

function AdminVideosHome() {
  const videos = useVideos(db);
  return (
    <>
      <div className="admin-grid">
        {videos
          .sort((a, b) => b.date - a.date)
          .map((video, index) => (
            <div className="video-card" key={index}>
              <div className="video-card-cover">
                {video.cover ? (
                  <>
                    <div
                      className="background"
                      style={{ backgroundColor: `#${video.color}` }}
                    ></div>
                    <img
                      src={video.cover}
                      alt={video.title}
                      className="video-cover-back"
                      loading="lazy"
                    />
                    <img
                      src={video.cover}
                      alt={video.title}
                      className="video-cover"
                      loading="lazy"
                    />
                  </>
                ) : (
                  <>
                    <div
                      className="background"
                      style={{ backgroundColor: `#${video.color}` }}
                    >
                      <span className="title">...</span>
                    </div>
                  </>
                )}
              </div>
              <div className="video-card-title">
                <a href={video.link} target="_blank" rel="noopener noreferrer">
                  <span className="video-title" title={video.title}>
                    {`Daniel Ospid - ${video.title}`}
                    {video.format && ` (${video.format})`}
                  </span>
                </a>
                <span className="video-date">
                  {video.date &&
                    new Date(video.date.seconds * 1000).toLocaleDateString(
                      "es",
                      {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }
                    )}
                  {true &&
                    (video.show ? (
                      <>
                        {" - Publico "}
                        <FontAwesomeIcon
                          icon={faEarthAmerica}
                          size="sm"
                          title="Publico"
                        />
                      </>
                    ) : (
                      <>
                        {" - Privado "}
                        <FontAwesomeIcon
                          icon={faLock}
                          size="sm"
                          title="Privado"
                        />
                      </>
                    ))}
                </span>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
export default AdminVideosHome;
