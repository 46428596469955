import { Outlet } from "react-router-dom";

function Main(props) {
  return (
    <main className="main">
      <Outlet addToBag={props.addToBag} />
    </main>
  );
}
export default Main;
