function VideoCardLoad() {
  return (
    <div className="video-card-load">
      <div className="video-load-cover"></div>
      <div className="video-load-details">
        <div className="video-load-title"></div>
        <div className="video-load-date"></div>
      </div>
    </div>
  );
}
export default VideoCardLoad;
