function MusicCardLoad() {
  return (
    <div className="music-card-load">
      <div className="music-load-cover"></div>
      <div className="music-load-details">
        <div className="music-load-title"></div>
        <div className="music-load-type"></div>
      </div>
    </div>
  );
}
export default MusicCardLoad;
