import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

function Copyright(props) {
  const year = new Date().getFullYear();

  const { t } = useTranslation();

  return (
    <div className="footer-copyright">
      <span className="copyright">
        <FontAwesomeIcon icon={faCopyright} />
        {year} Daniel Ospid - {t("footer.copyright")}.
      </span>
      <span className="policies-links">
        <ul className="policies">
          <li className="policy-item">
            <span className="policy-link" onClick={props.handleCookies}>
              Cookies
            </span>
          </li>
          <li className="policy-item">
            <Link to="/policies/cookies" className="policy-link">
              {t("policies.cookies.title")}
            </Link>
          </li>
          <li className="policy-item">
            <Link to="/policies/privacy" className="policy-link">
              {t("policies.privacy.title")}
            </Link>
          </li>
          <li className="policy-item">
            <Link to="/policies/terms" className="policy-link">
              {t("policies.terms.title")}
            </Link>
          </li>
        </ul>
      </span>
    </div>
  );
}

export default Copyright;
