import { Link } from "react-router-dom";
import { faHeartCrack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";

function NoFound() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {website_name} - {t("no-found.title")}
        </title>
      </Helmet>
      <div className="no-found">
        <div className="container">
          <span className="title-no-found">404</span>
          <span className="icon-no-found">
            <FontAwesomeIcon icon={faHeartCrack} />
          </span>
          <p className="text-no-found">{t("no-found.text")}.</p>
          <Link to="/" className="button primary-button">
            {t("no-found.button")}
          </Link>
        </div>
      </div>
    </>
  );
}
export default NoFound;
