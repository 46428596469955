import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import useIsAdmin, {
  useFans,
  useFeatured,
  useMusic,
  usePhotos,
  useVideos,
} from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import {
  faCirclePause,
  faCirclePlay,
} from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";

function About() {
  const isAdmin = useIsAdmin(db);

  const profile =
    "https://firebasestorage.googleapis.com/v0/b/danielospid.appspot.com/o/users%2Fdanielospid%2Fprofile.png?alt=media&token=17ab58a3-af72-4435-9e3d-24bc5e245bd7";

  const birthDate = new Date(2001, 7, 1, 12, 0);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  const featured = useFeatured(db);
  const photos = usePhotos(db);
  const music = useMusic(db);
  const videos = useVideos(db);
  const fans = useFans(db);

  const [play, setPlay] = useState(false);
  const audioRef = useRef(null);

  const handlePlay = () => {
    if (!play) {
      if (audioRef.current) {
        audioRef.current.play();
        setPlay(true);
      }
    } else {
      audioRef.current.pause();
      setPlay(false);
    }
  };

  const handleEnded = () => {
    setPlay(false);
  };

  const pinMusic = featured.find((music) => music.id === "music");

  return (
    <>
      <Helmet>
        <title>Sobre Mí - {website_name}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>Sobre Mí</h2>
            <div className="flex-action"></div>
          </div>
          <div className="about">
            <div className="about-header">
              <div className="about-profile">
                <img
                  src={profile}
                  alt="Foto de Perfil de Daniel Ospid"
                  style={{ width: 180 + "px" }}
                />
              </div>
              <div className="about-data">
                <div className="name">Daniel Ospid</div>
                <div className="username">@danielospid</div>
                <div className="fans">{`${fans.length} Fanáticos`}</div>
                {pinMusic && (
                  <>
                    {pinMusic.show && (
                      <>
                        <br />
                        <div className="block-music">
                          <button
                            onClick={handlePlay}
                            title={!play ? "Play" : "Pausa"}
                            aria-label={!play ? "Play" : "Pausa"}
                          >
                            <FontAwesomeIcon
                              icon={!play ? faCirclePlay : faCirclePause}
                              size="lg"
                            />
                          </button>
                          {pinMusic.spotify ? (
                            <a
                              href={pinMusic.spotify}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {pinMusic.title && (
                                <>
                                  <span className="title">
                                    {pinMusic.title}
                                  </span>
                                  {pinMusic.artist && (
                                    <span className="artist">
                                      {` • ${pinMusic.artist}`}
                                    </span>
                                  )}
                                </>
                              )}
                            </a>
                          ) : (
                            <a
                              href={`https://music.danielospid.com/${pinMusic.path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {pinMusic.title && (
                                <>
                                  <span className="title">
                                    {pinMusic.title}
                                  </span>
                                  {pinMusic.artist && (
                                    <span className="artist">
                                      {` • ${pinMusic.artist}`}
                                    </span>
                                  )}
                                </>
                              )}
                            </a>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="about-content">
              <div className="about-column">
                <article>
                  <h2>Autobiografía</h2>
                  <p>
                    Soy un chico salvadoreño apasionado por la música. Desde que
                    descubrí mi capacidad para escribir canciones, me he
                    dedicado a plasmar mis emociones en cada letra y a
                    interpretarlas con todo mi corazón. La música es mi refugio
                    y mi forma de conectar con el mundo, una vía para contar
                    historias, experiencias y sueños.
                  </p>
                </article>
                <article>
                  <h2>Biografía</h2>
                  <p>
                    Daniel Ospid es un cantautor independiente de El Salvador
                    que combina su talento para escribir e interpretar canciones
                    con un estilo propio y emocional. Nacido el 1 de agosto de
                    2001 en San Salvador, Daniel ha desarrollado una profunda
                    conexión con la música desde muy joven, lo que lo llevó a
                    iniciar su carrera musical de forma autodidacta. A través de
                    sus composiciones, Daniel refleja su sensibilidad artística
                    y sus vivencias, posicionándose como una promesa dentro de
                    la escena musical emergente en su país.
                  </p>
                </article>
                <article>
                  <h2>Carrera Musical</h2>
                  <p>
                    Daniel Ospid escribió su primera canción en 2019, a la edad
                    de 18 años, como parte de un proyecto escolar de ciencias
                    sobre las ondas sonoras. La primera persona en escuchar su
                    creación fue su maestra, seguida de su madre. Esta primera
                    canción ha evolucionado con el tiempo y ahora está en
                    proceso de producción para formar parte de un álbum más
                    ambicioso.
                  </p>
                  <p>
                    En 2021, lanzó su primer EP titulado{" "}
                    <i title="Drop Tracks, EP">Drop Tracks</i>, una colección de
                    seis canciones instrumentales que mostraba sus inicios en la
                    producción musical. Sin embargo, tras dos años y un
                    rendimiento moderado, decidió dar de baja el EP el 22 de
                    marzo de 2024, enfocándose en nuevos proyectos con mayor
                    potencial.
                  </p>
                  <p>
                    A pesar de sus compromisos universitarios, Daniel Ospid ha
                    estado trabajando en varias canciones y ha mencionado en
                    algunas publicaciones que su proceso creativo sigue en
                    marcha. Fuentes cercanas sugieren que podría lanzar un nuevo
                    sencillo a inicios del otro años, un tema que promete
                    reflejar su evolución como artista y su dedicación a la
                    música.
                  </p>
                </article>
                <article>
                  <h2>Galería</h2>
                  <div className="photos-grid">
                    {photos &&
                      photos
                        .slice(-6)
                        .sort((a, b) => b.date - a.date)
                        .map((photo, index) => (
                          <>
                            {photo.show && (
                              <img src={photo.image} alt="" key={index} />
                            )}
                          </>
                        ))}
                  </div>
                  <div className="photos-action">
                    <Link to="/photos" className="button primary-button ghost">
                      Ver Más Fotografías
                    </Link>
                  </div>
                </article>
                <article>
                  <h2>Discografía</h2>
                  <div className="table-row">
                    <table>
                      <thead>
                        <th>Título</th>
                        <th>Fecha</th>
                        <th>Tipo</th>
                        <th>Discográfica</th>
                      </thead>
                      <tbody>
                        {music
                          .sort((a, b) => b.date - a.date)
                          .map((music, index) => (
                            <>
                              {(music.show || isAdmin) ? (
                                <tr key={index}>
                                  <td>{music.title}</td>
                                  <td>
                                    {new Date(
                                      music.date.seconds * 1000
                                    ).toLocaleDateString("es", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </td>
                                  <td>{music.type}</td>
                                  <td>{music.label}</td>
                                </tr>
                              ) : null}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </article>
                <article>
                  <h2>Videografía</h2>
                  <div className="table-row">
                    <table>
                      <thead>
                        <th>Título</th>
                        <th>Fecha</th>
                        <th>Formato</th>
                        <th>Director</th>
                      </thead>
                      <tbody>
                        {videos.map((video, index) => (
                          <>
                            {(video.show || isAdmin) && (
                              <tr key={index}>
                                <td>{video.title}</td>
                                <td>
                                  {new Date(
                                    video.date.seconds * 1000
                                  ).toLocaleDateString("es", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })}
                                </td>
                                <td>{video.format}</td>
                                <td>{video.director}</td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
              <div className="about-aside">
                <div className="block-list">
                  <div className="title">Información Personal</div>
                  <ul className="list">
                    <li className="item">
                      <b>Nacimiento: </b>1 de agosto de 2001
                      {` (edad ${age} años)`}
                    </li>
                    <li className="item">
                      <b>Residencia: </b>San Salvador
                    </li>
                    <li className="item">
                      <b>Nacionalidad: </b>Salvadoreña
                    </li>
                    <li className="item">
                      <b>Lengua Materna: </b>Español
                    </li>
                  </ul>
                </div>
                <div className="block-list">
                  <div className="title">Características Físicas</div>
                  <ul className="list">
                    <li className="item">
                      <b>Altura: </b>1,67 m
                    </li>
                    <li className="item">
                      <b>Ojos: </b>Café
                    </li>
                    <li className="item">
                      <b>Cabello: </b>Negro
                    </li>
                  </ul>
                </div>
                <div className="block-list">
                  <div className="title">Información Profesional</div>
                  <ul className="list">
                    <li className="item">
                      <b>Ocupación: </b>Cantautor, Músico
                    </li>
                    <li className="item">
                      <b>Seudónimo: </b>Daniel Ospid
                    </li>
                    <li className="item">
                      <b>Géneros: </b>Pop, Rock, Trap, Pop en Español
                    </li>
                    <li className="item">
                      <b>Instrumentos: </b>Voz, Teclado, Guitarra Eléctrica
                    </li>
                    <li className="item">
                      <b>Discográfica: </b>Independiente
                    </li>
                    <li className="item">
                      <span className="link">
                        <b>Tienda: </b>
                        <a
                          href="https://shop.danielospid.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          shop.danielospid.com
                        </a>
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          size="sm"
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {pinMusic && (
              <div className="about-assets">
                <audio
                  src={pinMusic.song}
                  ref={audioRef}
                  onEnded={handleEnded}
                  controls
                  controlsList="nodownload"
                ></audio>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default About;
