import {
  faBars,
  faCircleUser,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIsAdmin from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { Link } from "react-router-dom";

function NavMenuIcons(props) {
  const isAdmin = useIsAdmin(db);
  return (
    <nav className="nav-menu">
      <ul className="nav-list">
        <li>
          <hr />
        </li>
        {isAdmin && (
          <li>
            <Link
              to="/admin"
              className="button icon-button"
              title="Panel de Control"
            >
              <FontAwesomeIcon icon={faCircleUser} size="xl" />
            </Link>
          </li>
        )}
        <li>
          <button
            type="button"
            className="button icon-button"
            onClick={props.handleMenu}
            title="Menú de navegación"
            aria-label="Abrir menú de navegación"
          >
            <FontAwesomeIcon icon={!props.menu ? faBars : faClose} size="2xl" />
          </button>
        </li>
      </ul>
    </nav>
  );
}
export default NavMenuIcons;
