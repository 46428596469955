import "../../assets/styles/app.css";

import { useEffect, useState } from "react";

import ScrollToTop from "../common/ScrollToTop";
import TopBar from "../common/TopBar";
import Header from "../common/Header";
import Menu from "../common/Menu";
import Main from "../common/Main";
import Footer from "../common/Footer";
import Cookies from "../common/Cookies";
import Glass from "../common/Glass";
import Language from "../common/Language";
import { useNavigate } from "react-router-dom";

function Layout(props) {
  const navegate = useNavigate();
  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
    setGlass(!glass);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Escape") {
        navegate("/");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "KeyM" && event.altKey) {
        handleMenu();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "F2") {
        navegate("/admin/login");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "F8") {
        navegate("/admin");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const [language, setLanguage] = useState(false);

  const handleLanguage = () => {
    setLanguage(!language);
    setGlass(true);
    setMenu(false);
  };

  const [glass, setGlass] = useState(false);

  const handleGlass = () => {
    setGlass(!glass);
    setMenu(false);
    setLanguage(false);
    setCookies(false);
  };

  const [cookies, setCookies] = useState(false);
  const [cookiesCount, setCookiesCount] = useState(0);
  const [allCookies, setAllCookies] = useState(false);
  const [publicity, setPublicity] = useState(false);
  const [analysis, setAnalysis] = useState(false);

  useEffect(() => {
    if (publicity && analysis) {
      setAllCookies(true);
    } else if (!publicity && !analysis) {
      setAllCookies(false);
    }
  }, [publicity, analysis]);

  useEffect(() => {
    // Verifica el modo actual almacenado en el almacenamiento local.
    const publicityCookies =
      localStorage.getItem("publicityCookies") === "true";
    setPublicity(publicityCookies);
  }, []);

  useEffect(() => {
    // Guarda el modo actual en el almacenamiento local.
    localStorage.setItem("publicityCookies", publicity.toString());
  }, [publicity]);

  useEffect(() => {
    // Verifica el modo actual almacenado en el almacenamiento local.
    const analysisCookies = localStorage.getItem("analysisCookies") === "true";
    setAnalysis(analysisCookies);
  }, []);

  useEffect(() => {
    // Guarda el modo actual en el almacenamiento local.
    localStorage.setItem("analysisCookies", analysis.toString());
  }, [analysis]);

  const handleCookies = () => {
    setCookies(!cookies);
    setGlass(!glass);
  };

  const handleAllCookies = () => {
    if (!allCookies) {
      setCookiesCount(2);
      setPublicity(true);
      setAnalysis(true);
      setAllCookies(true);
    } else {
      setCookiesCount(0);
      setPublicity(false);
      setAnalysis(false);
      setAllCookies(false);
    }
  };

  const handlePublicity = () => {
    if (publicity) {
      setCookiesCount(cookiesCount - 1);
      setPublicity(false);
    } else {
      setCookiesCount(cookiesCount + 1);
      setPublicity(true);
      setAllCookies(true);
    }
  };

  const handleAnalysis = () => {
    if (analysis) {
      setCookiesCount(cookiesCount - 1);
      setAnalysis(false);
    } else {
      setCookiesCount(cookiesCount + 1);
      setAnalysis(true);
      setAllCookies(true);
    }
  };

  const handleNoCookies = () => {
    if (allCookies === true) {
      setCookiesCount(0);
      setPublicity(false);
      setAnalysis(false);
      setAllCookies(false);
    }
  };

  return (
    <div className={!menu && !props.video ? "app" : "app no-scroll"}>
      <ScrollToTop />
      <TopBar />
      <Header
        menu={menu}
        handleLanguage={handleLanguage}
        handleMenu={handleMenu}
      />
      <Menu
        menu={menu}
        handleMenu={handleMenu}
        handleLanguage={handleLanguage}
      />
      <Language language={language} handleLanguage={handleLanguage} />
      <Main />
      <Footer handleCookies={handleCookies} />
      <Cookies
        cookies={cookies}
        handleCookies={handleCookies}
        cookiesCount={cookiesCount}
        allCookies={allCookies}
        handleAllCookies={handleAllCookies}
        publicity={publicity}
        handlePublicity={handlePublicity}
        analysis={analysis}
        handleAnalysis={handleAnalysis}
        handleNoCookies={handleNoCookies}
      />
      <Glass glass={glass} handleGlass={handleGlass} />
    </div>
  );
}

export default Layout;
