import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyARtf5puxtVH7j7H5EvCNl508doWzMrZgE",
  authDomain: "danielospid.firebaseapp.com",
  projectId: "danielospid",
  storageBucket: "danielospid.appspot.com",
  messagingSenderId: "511041489035",
  appId: "1:511041489035:web:d9effb60e0f4a90cf7ac8e",
  measurementId: "G-JTWJS0D5MT",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { auth, signInWithEmailAndPassword, db, storage, analytics };
