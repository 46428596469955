import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { db } from "../../firebase/config";
import { doc, setDoc } from "firebase/firestore";

function AdminNoticesCreate() {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { title, text, link, newPage, state } = data;
    const adRef = doc(db, "messages", title);

    try {
      await setDoc(adRef, {
        text,
        link,
        newPage,
        state: state === "true",
      });
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error en el registro", errorCode, errorMessage);
    }
  };

  return (
    <>
      <div className="admin-column">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="block">
            <input
              type="text"
              placeholder="Redacta en aviso"
              {...register("text")}
            />
          </div>
          <div className="block">
            <input
              type="text"
              placeholder="Inserta el enlace"
              {...register("link")}
            />
            <input
              type="checkbox"
              title="Abrir en una nueva página."
              {...register("newPage")}
            />
          </div>
          <div className="block">
            <select {...register("state")}>
              <option value="true">Publico</option>
              <option value="false">Privado</option>
            </select>
            <FontAwesomeIcon icon={faAngleDown} size="sm" className="down" />
          </div>
          <br />
          <div className="block">
            <span></span>
            <button type="submit" className="button primary-button">
              Crear Aviso
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
export default AdminNoticesCreate;
