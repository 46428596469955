import { Helmet } from "react-helmet";
import { useAuth, useMessages, useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowUpRightFromSquare,
  faEarthAmerica,
  faLock,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { website_name } from "../../database/config";

function Messages() {
  const messages = useMessages(db);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { title, text, link, newPage, state } = data;
    const adRef = doc(db, "messages", title);

    try {
      await setDoc(adRef, {
        text,
        link,
        newPage,
        state: state === "true",
      });
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error en el registro", errorCode, errorMessage);
    }
  };

  const deleteAd = async (id) => {
    const adRef = doc(db, "messages", id);

    try {
      await deleteDoc(adRef);
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  const users = useUsers(db);
  const authUser = useAuth(db);
  const admin = users.find(
    (user) => "hello@danielospid.com" === authUser.email
  );

  return (
    <>
      <Helmet>
        <title>Messages - {website_name}</title>
      </Helmet>
      {admin ? (
        <>
          <div className="app-header">
            <div className="flex">
              <span className="title">Messages</span>
            </div>
          </div>
          <div className="app-content column">
            <table className="table" cellSpacing={0}>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Message</th>
                  <th>Link</th>
                  <th>New Page</th>
                  <th>State</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {messages.map((message) => (
                  <tr key={message.id}>
                    <td>{message.id}</td>
                    <td>{message.text}</td>
                    <td>{message.link}</td>
                    <td>
                      {message.newPage && (
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                      )}
                    </td>
                    <td>
                      {message.state ? (
                        <FontAwesomeIcon icon={faEarthAmerica} />
                      ) : (
                        <FontAwesomeIcon icon={faLock} />
                      )}
                    </td>
                    <td className="icon">
                      <button
                        type="button"
                        title="Delete"
                        className="button icon-button"
                        onClick={() => deleteAd(message.id)}
                      >
                        <FontAwesomeIcon icon={faTrashCan} size="2xs" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <div className="block">
                <span className="title">New Message</span>
              </div>
              <div className="block">
                <input
                  type="text"
                  placeholder="Write the title (Not visible)"
                  {...register("title")}
                />
              </div>
              <div className="block">
                <input
                  type="text"
                  placeholder="Write the advertisement (Max. 60 characters)"
                  {...register("text", { maxLength: 60, required: true })}
                />
              </div>
              <div className="block">
                <input
                  type="text"
                  placeholder="Enter the link (Optional)"
                  {...register("link")}
                />
              </div>
              <div className="block">
                <select {...register("state", { required: true })}>
                  <option value="true">Public</option>
                  <option value="false">Hidden</option>
                </select>
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
              <div className="block">
                <span className="option-check">
                  <input
                    type="checkbox"
                    id="newPage"
                    {...register("newPage")}
                  />
                  <label htmlFor="newPage">Open in new page</label>
                </span>
              </div>
              <div className="block">
                <button type="submit" className="button primary-button">
                  Create message
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        "Mensages..."
      )}
    </>
  );
}
export default Messages;
