import { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import { useMessages } from "../../firebase/fbQueries";
import { Link } from "react-router-dom";

function TopBar() {
  const messages = useMessages(db);
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => (prevMessage + 1) % messages.length);
    }, 5000);
    return () => clearInterval(interval);
  });

  return (
    <div className="top-bar">
      <div className="container">
        <div className="flex-message">
          {messages
            .filter((item) => item.state)
            .map((message, index, self) => {
              if (
                message.link &&
                message.newPage === true &&
                message.link.startsWith("https://")
              ) {
                return (
                  <a
                    key={index}
                    href={message.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`message${
                      self.length === 1 || currentMessage === index
                        ? " show"
                        : ""
                    }`}
                  >
                    {message.text}
                  </a>
                );
              } else if (message.link) {
                return (
                  <Link
                    key={index}
                    to={message.link}
                    className={`message${
                      self.length === 1 || currentMessage === index
                        ? " show"
                        : ""
                    }`}
                  >
                    {message.text}
                  </Link>
                );
              } else {
                return (
                  <span
                    key={index}
                    className={`message${
                      self.length === 1 || currentMessage === index
                        ? " show"
                        : ""
                    }`}
                  >
                    {message.text}
                  </span>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
export default TopBar;
