import note from "../../assets/images/note.svg";
import noCover from "../../assets/images/coming-soon.gif";

function AdminMusicBox(props) {
  return (
    <div
      className="admin-music-box"
      onClick={() => props.handleSelectMusic(props.music)}
    >
      <figure className="admin-music-box-cover">
        {props.covers ? (
          <img src={props.covers} alt={props.title} />
        ) : (
          <img src={noCover} alt="No hay portada de este lanzamiento." />
        )}
        <div className="overlay-songs">
          <span className="overlay-songs-icon">
            <img src={note} alt="Note" />
          </span>
          <span className="overlay-songs-text">
            {props.songs.length > 1 ? (
              <>{props.songs.length + " Canciones"}</>
            ) : (
              <>{props.songs.length + " Canción"}</>
            )}
          </span>
        </div>
      </figure>
      <div className="admin-music-box-caption">
        <span className="title">{props.title}</span>
        <span className="type">
          {props.type}{" - "}
          {new Date(props.date.seconds * 1000).toLocaleDateString("es", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}
        </span>
      </div>
    </div>
  );
}
export default AdminMusicBox;
