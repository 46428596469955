import { Link } from "react-router-dom";
import note from "../../assets/images/note.svg";
import { useTranslation } from "react-i18next";
import useIsAdmin from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function MusicCard(props) {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin(db);

  return (
    <>
      {(props.show || isAdmin) && (
        <div className="music-card">
          <Link to={`/music/${props.path}`} className="music-card-link">
            <div
              className="music-card-cover"
              style={{ backgroundColor: `#${props.color}ab` }}
            >
              {props.covers
                ? props.covers && (
                    <img
                      src={props.covers[0]}
                      alt={"Portada de " + props.title}
                      className="music-cover"
                      loading="lazy"
                    />
                  )
                : "• • •"}
              {props.songs && (
                <div
                  className="music-card-songs"
                  style={{ backgroundColor: `#${props.color}80` }}
                >
                  <span className="music-card-songs-icon">
                    <img src={note} alt="Note" />
                  </span>
                  <span className="music-card-songs-text">
                    {props.songs.length > 1 ? (
                      <>{`${props.songs.length} ${t("music.label.songs")}`}</>
                    ) : (
                      <>{`${props.songs.length} ${t("music.label.song")}`}</>
                    )}
                  </span>
                </div>
              )}
              <div className="music-screen"></div>
            </div>
            <div className="music-card-caption">
              <span className="music-card-title">{props.title}</span>
              <span className="music-card-type">
                Daniel Ospid - {props.type}
              </span>
            </div>
          </Link>
        </div>
      )}
    </>
  );
}

export default MusicCard;
