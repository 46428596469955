import { db, storage } from "../../firebase/config";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { website_name } from "../../database/config";
import { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { v4 } from "uuid";

function Profile() {
  const auth = getAuth();
  const { register, handleSubmit, setValue } = useForm();
  const [imageFile, setImageFile] = useState(null);

  const authUser = useAuth(db);
  const users = useUsers(db);
  const userSelected = users.find((user) => user.email === authUser.email);

  useEffect(() => {
    if (userSelected) {
      setValue("image", userSelected.image);
      setValue("name", userSelected.name);
      setValue("lastName", userSelected.lastName);
      setValue("username", userSelected.username);
      setValue("email", userSelected.email);
      setValue("password", "");
    }
  }, [userSelected, setValue]);

  if (!authUser || !userSelected) {
    return <div>Cargando...</div>;
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const storageRef = ref(
        storage,
        `users/${userSelected.username}/profile/${v4()}`
      );
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setValue("image", downloadURL); // Establecer la URL en el campo de la imagen
    }
  };

  const onSubmit = async (data) => {
    const { image, firstName, lastName, rol, username, email, password } = data;

    if (!authUser.uid) {
      console.log("Error: uid es undefined");
      return;
    }

    try {
      const userDoc = doc(db, "users", authUser.uid);
      const docSnap = await getDoc(userDoc);

      if (!docSnap.exists()) {
        await setDoc(userDoc, {
          firstName,
          lastName,
          rol,
          username,
          email,
          password,
          image,
          updated: serverTimestamp(),
        });
      } else {
        const updateData = {
          image,
          firstName,
          lastName,
          // username,
          // email,
          updated: serverTimestamp(),
        };
        if (password) {
          updateData.password = password;
        }
        await updateDoc(userDoc, updateData);
        await signOut(auth);
      }

      console.log("Actualización exitosa");
      console.log("Cierre de sesión exitoso");
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error en la actualización", errorCode, errorMessage);
    }
  };

  const name = userSelected.firstName + " " + userSelected.lastName;

  return (
    <>
      <Helmet>
        <title>Perfil - {website_name}</title>
      </Helmet>

      <div className="admin-header">
        <div className="flex">
          <div className="title">Perfil</div>
        </div>
        <div className="flex"></div>
      </div>

      <div className="admin-body">
        <div className="profile">
          <div className="image">
            <img src={userSelected.image} alt={name} />
          </div>
          <div className="data">
            <span className="name">{name}</span>
            <span className="username">@{userSelected.username}</span>
            <span className="email">{userSelected.email}</span>
          </div>
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="block">
            <input
              type="url"
              placeholder="Enlace de la imagen"
              {...register("image")}
            />
            <input
              type="file"
              accept="image/*"
              id="selectedPicture"
              onChange={handleFileChange}
              hidden
            />
            <button
              type="button"
              className="button icon-button"
              title="Seleccionar foto de perfil"
              aria-label="Seleccionar foto de perfil"
            >
              <label htmlFor="selectedPicture" id="btnUpload">
                <FontAwesomeIcon icon={faCloudArrowUp} size="xl" />
              </label>
            </button>
          </div>
          <div className="block">
            <input
              type="text"
              placeholder="Nombre"
              {...register("firstName")}
            />
            <input
              type="text"
              placeholder="Apellido"
              {...register("lastName")}
            />
          </div>
          <div className="block" style={{ display: "none" }}>
            <input
              type="text"
              placeholder="Rol"
              disabled
              {...register("rol")}
            />
          </div>
          <div className="block">
            <input
              type="text"
              placeholder="Nombre de Usuario"
              autoComplete="false"
              disabled
              {...register("username", { maxLength: 20 })}
            />
          </div>
          <div className="block">
            <input
              type="email"
              placeholder="Correo Eletrónico"
              autoComplete="false"
              disabled
              {...register("email", {
                required: false,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              })}
            />
          </div>
          <div className="block">
            <input
              type="password"
              placeholder="Contraseña"
              autoComplete="false"
              {...register("password", { required: false, minLength: 8 })}
            />
          </div>
          <div className="block">
            <button type="submit" className="button primary-button">
              Update
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
export default Profile;
