import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "../../firebase/config";
import { useMessages } from "../../firebase/fbQueries";
import {
  faArrowUpRightFromSquare,
  faEarthAmerica,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

function AdminNoticesHome() {
  const notices = useMessages(db);
  return (
    <>
      <div className="admin-column">
        <div className="notice-item head">
          <div className="order">#</div>
          <div className="text">Mensaje</div>
          <div className="link">Enlace</div>
          <div className="state"></div>
        </div>
        {notices.map((notice, index) => (
          <div className="notice-item" key={index}>
            <div className="order">{index + 1}</div>
            <div className="text">{notice.text}</div>
            <div className="link">
              {notice.link ? (
                <>
                  {notice.link}
                  {notice.newPage && (
                    <>
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        size="xs"
                      />
                    </>
                  )}
                </>
              ) : (
                <>_ _ _</>
              )}
            </div>
            <div className="state">
              {notice.state ? (
                <FontAwesomeIcon icon={faEarthAmerica} />
              ) : (
                <FontAwesomeIcon icon={faLock} />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
export default AdminNoticesHome;
