import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { db } from "../../firebase/config";
import PhotoCard from "../common/PhotoCard";
import PhotoCardLoad from "../common/PhotoCardLoad";
import { usePhotos } from "../../firebase/fbQueries";
import { website_name } from "../../database/config";

function Photos() {
  const { t } = useTranslation();
  const photos = usePhotos(db);

  return (
    <>
      <Helmet>
        <title>
          {website_name} - {t("photos.title")}
        </title>
        <meta property="og:url" content="https://danielospid.com/photos" />
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>{t("photos.title")}</h2>
          </div>
          <div className="grid">
            {photos.length === 0 ? (
              <>
                <PhotoCardLoad />
                <PhotoCardLoad />
                <PhotoCardLoad />
                <PhotoCardLoad />

                <PhotoCardLoad />
                <PhotoCardLoad />
                <PhotoCardLoad />
                <PhotoCardLoad />
              </>
            ) : (
              photos
                .sort((a, b) => b.date - a.date)
                .map((photo, index) => <PhotoCard key={index} {...photo} />)
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default Photos;
