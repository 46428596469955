function BannerBox(props) {
  return (
    <div className="banner-box">
      <div className="banner-box-flex">
        <div className="banner-box-data">
          <h2>{props.title}</h2>
          <h3>{props.subtitle}</h3>
        </div>
        <div className="banner-box-action">
          <a
            href={`https://music.danielospid.com/${props.path}`}
            target="_blank"
            rel="noopener noreferrer"
            className="button primary-button"
          >
            {props.btn_text}
          </a>
        </div>
      </div>
      <div className="banner-box-flex">
        <a
          href={`https://music.danielospid.com/${props.path}`}
          target="_blank"
          rel="noopener noreferrer"
          className="banner-box-image"
          title={props.title}
        >
          {props.cover ? (
            <img src={props.cover} alt={props.title} loading="lazy" />
          ) : (
            <div
              className="box"
              style={{ backgroundColor: `#${props.color}` }}
            >
              • • •
            </div>
          )}
        </a>
      </div>
    </div>
  );
}
export default BannerBox;
