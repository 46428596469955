import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useAuth, useFans, useUsers } from "../../firebase/fbQueries";
import { website_name } from "../../database/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function Fans() {
  const fans = useFans(db);

  const users = useUsers(db);
  const authUser = useAuth(db);
  const admin = users.find(
    (user) => "hello@danielospid.com" === authUser.email
  );

  const [searchTerm, setSearchTerm] = useState("");

  const sameFans = fans.filter((fan) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    // Filtrar por email, nombre, apellido y país
    const matchesSearch = fan.email.toLowerCase().includes(lowerCaseSearchTerm);

    return matchesSearch;
  });

  return (
    <>
      <Helmet>
        <title>Fanáticos - {website_name}</title>
      </Helmet>

      {admin && (
        <>
          <div className="admin-header">
            <div className="flex">
              <span className="title">Fanáticos</span>
            </div>
            <div className="flex">
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Buscar fanático por correo"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} size="sm" color="#757575" />
              </div>
            </div>
          </div>

          <div className="admin-body">
            <div className="admin-grid">
              {sameFans
                .sort((a, b) => b.since - a.since)
                .map((fan, index) => (
                  <div className="fans-box" key={index}>
                    <span className="name">
                      {fan.firstName + " " + fan.lastName}
                    </span>
                    <span className="email" title={fan.email}>
                      <b>Correo: </b>
                      {fan.email}
                    </span>
                    <span className="country">
                      <b>País: </b>
                      {fan.country}
                    </span>
                    <span className="date">
                      <b>Desde: </b>
                      {new Date(fan.since.seconds * 1000).toLocaleString("es", {
                        weekday: "short",
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Fans;
