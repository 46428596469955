import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth, useConfigWebsite } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function ProductCard(props) {
  const { t } = useTranslation();
  const authUser = useAuth(db);
  const configWebsite = useConfigWebsite(db);
  return (
    <>
      {(props.show_product || authUser) && (
        <div className="product-card">
          {configWebsite.show_store ? (
            <>
              <Link to={`/products/${props.path}`}>
                <div className="product-figure">
                  <>
                    {props.show_pre && (
                      <span className="dto">{t("store.product.pre")}</span>
                    )}
                    {props.show_dto && (
                      <span className="dto">{t("store.product.dto")}</span>
                    )}
                    {props.show_sot && (
                      <span className="sot">{t("store.product.sot")}</span>
                    )}
                  </>
                  <img
                    src={props.img_def}
                    alt={props.title}
                    className="img_def"
                    loading="lazy"
                  />
                  {props.img_hov && (
                    <>
                      <div className="back-background"></div>
                      <img
                        src={props.img_hov}
                        alt={props.title}
                        className="img_hov"
                        loading="lazy"
                      />
                    </>
                  )}
                  <div className="product-screen"></div>
                </div>
              </Link>
              <div className="product-details">
                <div className="product-details-title">
                  <Link to={`/products/${props.path}`} title={props.title}>
                    {props.title}
                  </Link>
                </div>
                <div className="product-details-price">
                  ${props.price.toFixed(2)}{" "}
                  {props.until && <> - ${props.until.toFixed(2)}</>}
                </div>
              </div>
            </>
          ) : (
            <>
              <a
                href={`https://shop.danielospid.com/products/${props.path}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="product-figure">
                  <>
                    {props.show_pre && (
                      <span className="dto">{t("store.product.pre")}</span>
                    )}
                    {props.show_dto && (
                      <span className="dto">{t("store.product.dto")}</span>
                    )}
                    {props.show_sot && (
                      <span className="sot">{t("store.product.sot")}</span>
                    )}
                  </>
                  <img
                    src={props.img_def}
                    alt={props.title}
                    className="img_def"
                    loading="lazy"
                  />
                  {props.img_hov && (
                    <>
                      <div className="back-background"></div>
                      <img
                        src={props.img_hov}
                        alt={props.title}
                        className="img_hov"
                        loading="lazy"
                      />
                    </>
                  )}
                  <div className="product-screen"></div>
                </div>
              </a>
              <div className="product-details">
                <div className="product-details-title">
                  <a
                    href={`https://shop.danielospid.com/products/${props.path}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={props.title}
                  >
                    {props.title}
                  </a>
                </div>
                <div className="product-details-price">
                  ${props.price.toFixed(2)}{" "}
                  {props.until && <> - ${props.until.toFixed(2)}</>}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ProductCard;
