import {
  faHouse,
  faMessage,
  faMusic,
  faVideo,
  faUser,
  faSignOut,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAuth, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import useIsAdmin from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function AdminMenu() {
  const isAdmin = useIsAdmin(db);
  const auth = getAuth();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Cierre de sesión exitoso");
    } catch (error) {
      console.log("Error al cerrar la sesión", error);
    }
  };

  return (
    <div className="admin-menu">
      <nav className="admin-nav-menu">
        <ul className="admin-nav-list">
          <li className="admin-nav-item">
            <Link to="/admin" className="admin-nav-link">
              <div className="admin-nav-wrapper">
                <span className="admin-nav-icon">
                  <FontAwesomeIcon icon={faHouse} />
                </span>
                <span className="admin-nav-text">Panel de Control</span>
              </div>
            </Link>
          </li>
          {isAdmin && (
            <>
              <li className="admin-nav-item">
                <Link to="/admin/music" className="admin-nav-link">
                  <div className="admin-nav-wrapper">
                    <span className="admin-nav-icon">
                      <FontAwesomeIcon icon={faMusic} />
                    </span>
                    <span className="admin-nav-text">Música</span>
                  </div>
                </Link>
              </li>
              <li className="admin-nav-item">
                <Link to="/admin/videos" className="admin-nav-link">
                  <div className="admin-nav-wrapper">
                    <span className="admin-nav-icon">
                      <FontAwesomeIcon icon={faVideo} />
                    </span>
                    <span className="admin-nav-text">Vídeos</span>
                  </div>
                </Link>
              </li>
              <li className="admin-nav-item">
                <Link to="/admin/notices" className="admin-nav-link">
                  <div className="admin-nav-wrapper">
                    <span className="admin-nav-icon">
                      <FontAwesomeIcon icon={faMessage} />
                    </span>
                    <span className="admin-nav-text">Avisos</span>
                  </div>
                </Link>
              </li>
              <li className="admin-nav-item">
                <Link to="/admin/fans" className="admin-nav-link">
                  <div className="admin-nav-wrapper">
                    <span className="admin-nav-icon">
                      <FontAwesomeIcon icon={faUserGroup} />
                    </span>
                    <span className="admin-nav-text">Fanáticos</span>
                  </div>
                </Link>
              </li>
            </>
          )}
          <li className="admin-nav-item">
            <Link to="/admin/profile" className="admin-nav-link">
              <div className="admin-nav-wrapper">
                <span className="admin-nav-icon">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <span className="admin-nav-text">Perfil</span>
              </div>
            </Link>
          </li>
          <li className="admin-nav-item">
            <div className="admin-nav-link" onClick={handleSignOut}>
              <div className="admin-nav-wrapper">
                <span className="admin-nav-icon">
                  <FontAwesomeIcon icon={faSignOut} />
                </span>
                <span className="admin-nav-text">Cerrar Sesión</span>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default AdminMenu;
