import { Helmet } from "react-helmet";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { Link, Outlet } from "react-router-dom";

function AdminNotices() {
  const users = useUsers(db);
  const authUser = useAuth(db);
  const admin = users.find(
    (user) => "hello@danielospid.com" === authUser.email
  );

  return (
    <>
      <Helmet>
        <title>Avisos - Panel de Control</title>
      </Helmet>
      {admin ? (
        <>
          <div className="admin-header">
            <div className="flex">
              <div className="title">Avisos</div>
            </div>
            <div className="flex">
              <Link to="/admin/notices">Inicio</Link> /
              <Link to="/admin/notices/create">Crear</Link> /
              <Link to="/admin/notices/update">Actualizar</Link> /
              <Link to="/admin/notices/delete">Eliminar</Link>
            </div>
          </div>
          <div className="admin-body">
            <Outlet />
          </div>
        </>
      ) : null}
    </>
  );
}
export default AdminNotices;
