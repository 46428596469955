function ProductCardLoad() {
  return (
    <div className="product-card-load">
        <div className="product-load-figure"></div>
        <div className="product-load-details">
            <div className="product-load-title"></div>
            <div className="product-load-price"></div>
        </div>
    </div>
  )
}
export default ProductCardLoad