import AdminMenu from "./AdminMenu";
import AdminContent from "./AdminContent";

function Admin() {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="admin">
            <AdminMenu />
            <AdminContent />
          </div>
        </div>
      </section>
    </>
  );
}
export default Admin;
