import { useState } from "react";
import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import MusicCardLoad from "../common/MusicCardLoad";
import { usePlaylists } from "../../firebase/fbQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { website_name } from "../../database/config";
import PlaylistCard from "../common/PlaylistCard";

function Playlists() {
  const { t } = useTranslation();
  const playlists = usePlaylists(db);
  const [searchTerm, setSearchTerm] = useState("");
  const sameTitleMusic = playlists.filter((music) => {
    const matchesSearch = music.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  return (
    <>
      <Helmet>
        <title>
          {t("title.playlists")} - {website_name}
        </title>
        <meta property="og:url" content="https://danielospid.com/playlists" />
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>{t("title.playlists")}</h2>
            <div className="flex-action">
              <div className="select-wrapper">
                <input
                  type="text"
                  placeholder={t("music.label.search")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} size="sm" color="#757575" />
              </div>
            </div>
          </div>
          <div className="grid">
            {sameTitleMusic.length === 0 ? (
              <>
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />

                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
                <MusicCardLoad />
              </>
            ) : (
              sameTitleMusic
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((music, index) => <PlaylistCard key={index} {...music} />)
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Playlists;
