import { useEffect, useState } from "react";
import Logo from "./Logo";
import NavMenu from "./NavMenu";
import NavMenuIcons from "./NavMenuIcons";

function Header(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("none");

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollDirection(currentPosition > scrollPosition ? "down" : "up");
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const headerStyle = {
    top: scrollDirection === "down" ? "0" : "30px",
  };

  return (
    <header className="header" style={headerStyle}>
      <div className="container">
        <div className="flex">
          <Logo />
        </div>
        <div className="flex">
          <NavMenu />
          <NavMenuIcons
            handleLanguage={props.handleLanguage}
            menu={props.menu}
            handleMenu={props.handleMenu}
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
