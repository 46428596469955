import { db } from "../../firebase/config";
import { Link, useParams } from "react-router-dom";
import useIsAdmin, {
  useConfigWebsite,
  useMusic,
} from "../../firebase/fbQueries";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faFeather,
} from "@fortawesome/free-solid-svg-icons";
import { faSpotify, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { website_name } from "../../database/config";
import { useState } from "react";
import NoFound from "./NoFound";

function MusicPage() {
  const isAdmin = useIsAdmin(db);
  const { t } = useTranslation();
  const { path } = useParams();
  const music = useMusic(db);
  const musicSelected = music.find((music) => music.path === path);
  const configWebsite = useConfigWebsite(db);

  const [coverSelected, setCoverSelected] = useState(null);

  const changeCover = (cover) => {
    setCoverSelected(cover);
  };

  const price_song_low = 0.99;

  return (
    <>
      {musicSelected ? (
        musicSelected.show || isAdmin ? (
          <>
            <Helmet>
              <title>
                {musicSelected.title} - {website_name}
              </title>
            </Helmet>
            <section className="section">
              <div className="container">
                <div className="music-page">
                  <div className="music-page-image">
                    <div className="music-cover-wrapper">
                      {musicSelected.covers ? (
                        <>
                          {coverSelected && (
                            <img
                              src={coverSelected}
                              alt={musicSelected.title}
                              className="music-cover"
                              loading="lazy"
                            />
                          )}
                          {!coverSelected && (
                            <img
                              src={musicSelected.covers[0]}
                              alt={musicSelected.title}
                              className="music-cover"
                              loading="lazy"
                            />
                          )}
                        </>
                      ) : (
                        <div
                          className="music-cover"
                          style={{
                            backgroundColor: `#${musicSelected.color}ab`,
                          }}
                        >
                          • • •
                        </div>
                      )}
                    </div>
                    {musicSelected.covers &&
                      musicSelected.covers.length > 1 && (
                        <div className="music-page-grid">
                          {musicSelected.covers.map((cover, index) => (
                            <div
                              key={index}
                              className="music-cover-item"
                              style={{
                                backgroundColor: `#${musicSelected.color}`,
                              }}
                              onClick={() => changeCover(cover)}
                            >
                              {cover ? (
                                <img
                                  src={cover}
                                  alt={
                                    "Cover N° " +
                                    (index + 1) +
                                    " " +
                                    musicSelected.title
                                  }
                                />
                              ) : (
                                <div
                                  className="background"
                                  style={{
                                    backgroundColor: `#${musicSelected.color}`,
                                  }}
                                >
                                  <span className="title">...</span>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                  <div className="music-page-data">
                    {/* título & fecha */}
                    <div className="music-block column">
                      {musicSelected.title && (
                        <h1 className="title">{musicSelected.title}</h1>
                      )}
                      {musicSelected.date && (
                        <span className="date">
                          {new Date(
                            musicSelected.date.seconds * 1000
                          ).toLocaleDateString("es", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                          {musicSelected.type && ` • ${musicSelected.type}`}
                        </span>
                      )}
                    </div>
                    {/* precios */}
                    {isAdmin && (
                      <div className="music-block">
                        {musicSelected.songs && (
                          <table>
                            <thead>
                              <tr>
                                <th>Digital</th>
                                <th>CD</th>
                                <th>Casete</th>
                                <th>Vinilo</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  $
                                  {(
                                    musicSelected.songs.length * price_song_low
                                  ).toFixed(2)}
                                  {" USD"}
                                </td>
                                <td>
                                  $
                                  {(
                                    musicSelected.songs.length *
                                      price_song_low +
                                    configWebsite.price_cd
                                  ).toFixed(2)}
                                  {" USD"}
                                </td>
                                <td>
                                  $
                                  {(
                                    musicSelected.songs.length *
                                      price_song_low +
                                    configWebsite.price_casete
                                  ).toFixed(2)}
                                  {" USD"}
                                </td>
                                <td>
                                  $
                                  {(
                                    musicSelected.songs.length *
                                      price_song_low +
                                    configWebsite.price_vinilo
                                  ).toFixed(2)}
                                  {" USD"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>
                    )}
                    {/* botones */}
                    <div className="music-block row">
                      <a
                        href={
                          "https://music.danielospid.com/" + musicSelected.path
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button primary-button"
                      >
                        {t("button.listen")}
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          size="xs"
                        />
                      </a>
                      {musicSelected.spotify && (
                        <a
                          href={musicSelected.spotify}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button primary-button spotify"
                          title="Spotify"
                        >
                          <FontAwesomeIcon icon={faSpotify} size="lg" />
                        </a>
                      )}
                      {musicSelected.youtube && (
                        <a
                          href={musicSelected.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button primary-button youtube"
                          title="YouTube"
                        >
                          <FontAwesomeIcon icon={faYoutube} size="lg" />
                        </a>
                      )}
                      {musicSelected.type === "Sencillo" && (
                        <Link
                          to={`/lyrics/${musicSelected.path}`}
                          className="button primary-button"
                          title="Letra"
                        >
                          <FontAwesomeIcon icon={faFeather} size="lg" />
                        </Link>
                      )}
                    </div>
                    {/* lista de canciones */}
                    {musicSelected.songs && (
                      <div className="music-block column">
                        <b className="subtitle">
                          {musicSelected.songs.length > 1
                            ? "Lista de canciones"
                            : "Canción"}
                        </b>
                        <ol className="order-list">
                          {musicSelected.songs.map((song, index) => (
                            <li key={index} className="item-list">
                              {song}
                            </li>
                          ))}
                        </ol>
                      </div>
                    )}
                    {/* intérpretes */}
                    {musicSelected.interpreters && (
                      <div className="music-block column">
                        <b className="subtitle">
                          {musicSelected.interpreters.length > 1
                            ? "Intérpretes"
                            : "Intérprete"}
                        </b>
                        <span className="text">
                          {musicSelected.interpreters.join(", ")}
                        </span>
                      </div>
                    )}
                    {/* compositores */}
                    {musicSelected.composers && (
                      <div className="music-block column">
                        <b className="subtitle">
                          {musicSelected.composers.length > 1
                            ? "Compositores"
                            : "Compositor"}
                        </b>
                        <span className="text">
                          {musicSelected.composers.join(", ")}
                        </span>
                      </div>
                    )}
                    {/* patrocinadores */}
                    {musicSelected.sponsors && (
                      <div className="music-block column">
                        <b className="subtitle">
                          {musicSelected.sponsors.length > 1
                            ? "Patrocinadores"
                            : "Patrocinador"}
                        </b>
                        <span className="text">
                          {musicSelected.sponsors.join(", ")}
                        </span>
                      </div>
                    )}
                    {/* discográfica */}
                    {musicSelected.label && (
                      <div className="music-block column">
                        <b className="subtitle">
                          {musicSelected.label.length > 1
                            ? "Discográficas"
                            : "Discográfica"}
                        </b>
                        <span className="text">
                          {musicSelected.label.join(", ")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <h1>No tienes acceso a está página.</h1>
            <p>Esta letra aun no está publicada.</p>
          </>
        )
      ) : (
        <NoFound />
      )}
    </>
  );
}
export default MusicPage;
