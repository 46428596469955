import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
import { useTranslation } from "react-i18next";

function Support() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t("title.support")} - {website_name}
        </title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>{t("title.support")}</h2>
          </div>
        </div>
      </section>
    </>
  );
}
export default Support;
