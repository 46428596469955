import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { website_name } from "../../database/config";

function Cookies() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{website_name} - Política de Cookies</title>
        <meta
          property="og:url"
          content="https://danielospid.com/policies/cookies"
        />
      </Helmet>

      <section className="section">
        <div className="container">
          <article className="article">
            <h1>Política de Cookies</h1>
            <hr />
            <p>
              Bienvenido a la política de cookies. Esta página explica cómo
              utilizamos las cookies en nuestro sitio web. Al continuar
              utilizando este sitio, aceptas el uso de cookies de acuerdo con
              esta política.
            </p>
            <p>
              <b>¿Qué son las cookies?</b>
              Las cookies son pequeños archivos de texto que se almacenan en tú
              dispositivo cuando visitas un sitio web. Estas cookies contienen
              información que se utiliza para mejorar la experiencia del usuario
              y para proporcionar datos anónimos a los propietarios del sitio.
            </p>
            <p>
              <b>Tipos de Cookies que Utilizamos</b>
              {/* cookies necesarias */}
              <p>
                <b>
                  <u>{t("policies.cookies.content.cookies.title.necessary")}</u>
                  :
                </b>{" "}
                {t("policies.cookies.content.cookies.text.necessary")}
              </p>
              {/* cookies de rendimiento */}
              <p>
                <b>
                  <u>
                    {t("policies.cookies.content.cookies.title.performance")}
                  </u>
                  :
                </b>{" "}
                {t("policies.cookies.content.cookies.text.performance")}
              </p>
              {/* cookies de funcionalidad */}
              <p>
                <b>
                  <u>
                    {t("policies.cookies.content.cookies.title.functionality")}
                  </u>
                  :
                </b>{" "}
                {t("policies.cookies.content.cookies.text.functionality")}
              </p>
              {/* cookies de publicidad */}
              <p>
                <b>
                  <u>
                    {t("policies.cookies.content.cookies.title.advertising")}
                  </u>
                  :
                </b>{" "}
                {t("policies.cookies.content.cookies.text.advertising")}
              </p>
            </p>
            <p>
              <b>Control de Cookies</b>
              Puedes controlar y/o eliminar las cookies según tus preferencias.
              Para obtener más información, visita la página de configuración de
              las cookies.
            </p>
            <p>
              <b>Cambios en la Política de Cookies</b>
              Nos reservamos el derecho de actualizar esta política en cualquier
              momento. Cualquier cambio significativo se comunicará a través de
              una notificación en nuestro sitio web.
            </p>
            <p>
              Al utilizar este sitio web, usted acepta esta política de cookies
              en su totalidad. Si no está de acuerdo con esta política de
              cookies, no utilice este sitio web.
            </p>
            <p>
              <b>Última actualización:</b> 08 de junio de 2024
            </p>
          </article>
        </div>
      </section>
    </>
  );
}
export default Cookies;
