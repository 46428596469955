import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useConfigWebsite } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { website_url } from "../../database/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function NavMenu(props) {
  const { t } = useTranslation();
  const configWebsite = useConfigWebsite(db);

  return (
    <nav className="nav-menu" id="navMenu">
      <ul className="nav-list">
        <li className="nav-item" onClick={props.click}>
          <Link to="/music" className="nav-link">
            {t("title.music")}
          </Link>
        </li>
        <li className="nav-item" onClick={props.click}>
          <Link to="/videos" className="nav-link">
            {t("title.videos")}
          </Link>
        </li>
        <li className="nav-item" onClick={props.click}>
          <Link to="/lyrics" className="nav-link">
            {t("title.lyrics")}
          </Link>
        </li>
        <li className="nav-item" onClick={props.click}>
          <Link to="/photos" className="nav-link">
            {t("title.photos")}
          </Link>
        </li>
        {configWebsite.show_store && (
          <li className="nav-item" onClick={props.click}>
            <a
              href={`https://shop.${website_url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              {t("title.shop")}
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                title="Nueva pestaña"
              />
            </a>
          </li>
        )}
        <li className="nav-item" onClick={props.click}>
          <Link to="/about" className="nav-link">
            {t("title.about")}
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavMenu;
