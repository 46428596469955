import { Link } from "react-router-dom";

function PrimaryLink(props) {
  return (
      <Link
        to={props.path}
        className="button primary-button"
      >
        {props.text}
      </Link>
  );
}
export default PrimaryLink;
