import i18next from "i18next";
import { useState } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Language(props) {
  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18next.changeLanguage(selectedLanguage);
  };

  const [openSelect, setOpenSelect] = useState(false);

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  return (
    <div className={!props.language ? "language" : "language show"}>
      {props.language && (
        <div className="language-select">
          <select
            title="Select your language"
            id="select"
            onChange={handleChangeLanguage}
            onClick={handleSelect}
          >
            <option value="es" title="Español" selected>
              {"Español (España)"}
            </option>
            <option value="en" title="English">
              {"English (United States)"}
            </option>
          </select>
          <button type="button" className="button icon-button">
            <FontAwesomeIcon icon={faAngleDown} />
          </button>
        </div>
      )}
    </div>
  );
}
export default Language;
