import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { website_name } from "../../database/config";
import useIsAdmin, { useLyrics } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import NoFound from "./NoFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function LyricPage() {
  const isAdmin = useIsAdmin(db);
  const { path } = useParams();
  const lyrics = useLyrics(db);

  const lyricSelected = lyrics.find(
    (lyric) => lyric.id === path || lyric.path === path
  );

  const htmlStringContent = lyricSelected ? lyricSelected.content : "";

  const [show, setShow] = useState();

  const showBackground = () => {
    setShow(!show);
  };

  return (
    <>
      {lyricSelected ? (
        <>
          {lyricSelected.show || isAdmin ? (
            <>
              <Helmet>
                <title>
                  {lyricSelected.title} - {website_name}
                </title>
              </Helmet>

              {lyricSelected.cover ? (
                <>
                  <div
                    className={!show ? "background" : "background show"}
                    style={{
                      backgroundImage: `URL(${lyricSelected.cover})`,
                    }}
                  ></div>
                  <div
                    className={!show ? "background-i" : "background-i show"}
                    style={{
                      backgroundImage: `URL(${lyricSelected.cover})`,
                    }}
                  ></div>
                  <div
                    className={!show ? "background-ii" : "background-ii show"}
                    style={{
                      backgroundImage: `URL(${lyricSelected.cover})`,
                    }}
                  ></div>
                  <div className="background-glass"></div>
                </>
              ) : (
                <>
                  <div
                    className="background show mask"
                    style={{ backgroundColor: `#${lyricSelected.color}ab` }}
                  ></div>
                  <div className="background-glass"></div>
                </>
              )}

              <section className="section">
                <div className="container">
                  <div className="lyric-page">
                    <article className="article">
                      <h1 className="title" onClick={() => showBackground()}>
                        {lyricSelected.title}
                      </h1>
                      {lyricSelected.content && (
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: htmlStringContent,
                          }}
                        ></div>
                      )}
                      {lyricSelected.composers && (
                        <div className="copyright">
                          <hr />
                          <p className="copy-block">
                            <b className="subtitle">
                              {lyricSelected.composers.length > 1
                                ? "Compositores"
                                : "Compositor"}
                            </b>
                            <span className="text">
                              {lyricSelected.composers.join(", ")}
                            </span>
                          </p>
                        </div>
                      )}
                    </article>
                    <div className="row-action">
                      <Link to="/lyrics" className="button primary-button">
                        <FontAwesomeIcon icon={faArrowLeft} />
                        Volver A Las Letras
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <>
              <h1>No tienes acceso a está página.</h1>
              <p>Esta letra aun no está publicada.</p>
            </>
          )}
        </>
      ) : (
        <>
          <NoFound />
        </>
      )}
    </>
  );
}
export default LyricPage;
