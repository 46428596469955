import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./firebase/fbQueries";
import { db } from "./firebase/config";
import { useState } from "react";

import Layout from "./components/pages/Layout";
import Home from "./components/pages/Home";
import Music from "./components/pages/Music";
import MusicPage from "./components/pages/MusicPage";
import Videos from "./components/pages/Videos";
import Photos from "./components/pages/Photos";
import PhotoPage from "./components/pages/PhotoPage";
import Store from "./components/pages/Store";
import Product from "./components/pages/Product";
import Category from "./components/pages/Category";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import NoFound from "./components/pages/NoFound";
import Cookies from "./components/pages/Cookies";
import LyricPage from "./components/pages/LyricPage";
import About from "./components/pages/About";

import Login from "./components/pages/Login";
import Admin from "./components/admin/Admin";
import AdminMusic from "./components/admin/AdminMusic";
import AdminVideos from "./components/admin/AdminVideos";
import AdminFans from "./components/admin/AdminFans";
import AdminMessages from "./components/pages/Messages";
import AdminProfile from "./components/admin/AdminProfile";
import AdminDashboard from "./components/admin/AdminDashboard";
import Support from "./components/pages/Support";
import Lyrics from "./components/pages/Lyrics";
import Playlists from "./components/pages/Playlists";
import AdminNotices from "./components/admin/AdminNotices";
import AdminMusicHome from "./components/admin/AdminMusicHome";
import AdminNoticesHome from "./components/admin/AdminNoticesHome";
import AdminNoticesCreate from "./components/admin/AdminNoticesCreate";
import AdminVideosHome from "./components/admin/AdminVideosHome";

function App() {
  const authUser = useAuth(db);

  const [video, setVideo] = useState(false);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout video={video} />}>
          <Route
            path="/"
            element={<Home video={video} setVideo={setVideo} />}
          />
          <Route path="/music" element={<Music />} />
          <Route path="/music/:path" element={<MusicPage />} />
          <Route path="/playlists" element={<Playlists />} />
          <Route
            path="/videos"
            element={<Videos video={video} setVideo={setVideo} />}
          />
          <Route path="/photos" element={<Photos />} />
          <Route path="/photos/:id" element={<PhotoPage />} />
          <Route path="/lyrics" element={<Lyrics />} />
          <Route path="/lyrics/:path" element={<LyricPage />} />
          <Route path="/store" element={<Store />} />
          <Route path="/products" element={<Store />} />
          <Route path="/products/:path" element={<Product />} />
          <Route path="/categories/:category" element={<Category />} />
          <Route path="/about" element={<About />} />
          <Route path="/support" element={<Support />} />
          <Route path="/policies/cookies" element={<Cookies />} />
          <Route path="/policies/privacy" element={<Privacy />} />
          <Route path="/policies/terms" element={<Terms />} />

          {authUser ? (
            <Route element={<Admin />}>
              <Route path="/admin" element={<AdminDashboard />} />
              <Route element={<AdminMusic />}>
                <Route path="/admin/music" element={<AdminMusicHome />} />
                <Route path="/admin/music/create" element={<>x</>} />
                <Route path="/admin/music/update" element={<>x</>} />
                <Route path="/admin/music/delete" element={<>x</>} />
              </Route>
              <Route element={<AdminVideos />}>
                <Route path="/admin/videos" element={<AdminVideosHome />} />
                <Route
                  path="/admin/videos/create"
                  element={<AdminVideosHome />}
                />
                <Route
                  path="/admin/videos/update"
                  element={<AdminVideosHome />}
                />
                <Route
                  path="/admin/videos/delete"
                  element={<AdminVideosHome />}
                />
              </Route>
              <Route element={<AdminNotices />}>
                <Route path="/admin/notices" element={<AdminNoticesHome />} />
                <Route
                  path="/admin/notices/create"
                  element={<AdminNoticesCreate />}
                />
                <Route
                  path="/admin/notices/update"
                  element="Actualizar aviso"
                />
                <Route path="/admin/notices/delete" element="Eliminar aviso" />
              </Route>
              <Route path="/admin/messages" element={<AdminMessages />} />
              <Route path="/admin/stock" element={"Stock..."} />
              <Route path="/admin/fans" element={<AdminFans />} />
              <Route path="/admin/profile" element={<AdminProfile />} />
            </Route>
          ) : (
            <>
              <Route path="/admin/*" element={<Navigate to="/" replace />} />
            </>
          )}

          <Route path="/index.html" element={<Navigate to="/" replace />} />
          <Route path="/index" element={<Navigate to="/" replace />} />
          <Route path="/home" element={<Navigate to="/" replace />} />
          <Route path="/inicio" element={<Navigate to="/" replace />} />
          <Route path="/blog" element={<Navigate to="/" replace />} />
          <Route path="/tours" element={<Navigate to="/" replace />} />
          <Route path="/musica" element={<Navigate to="/music" replace />} />
          <Route path="/música" element={<Navigate to="/music" replace />} />
          <Route path="/fotos" element={<Navigate to="/photos" replace />} />
          <Route path="/tienda" element={<Navigate to="/store" replace />} />
          <Route path="/shop" element={<Navigate to="/store" replace />} />
          <Route path="/shopify" element={<Navigate to="/store" replace />} />
          <Route
            path="/storefront"
            element={<Navigate to="/store" replace />}
          />
          <Route
            path="/escaparate"
            element={<Navigate to="/store" replace />}
          />
          <Route
            path="/storefront"
            element={<Navigate to="/store" replace />}
          />
          <Route
            path="/escaparate"
            element={<Navigate to="/store" replace />}
          />
          <Route path="/products" element={<Navigate to="/store" replace />} />
          <Route path="/productos" element={<Navigate to="/store" replace />} />
          <Route
            path="/categories"
            element={<Navigate to="/store" replace />}
          />
          <Route
            path="/categorias"
            element={<Navigate to="/store" replace />}
          />
          <Route path="*" element={<NoFound />} />
        </Route>

        <Route path="/admin/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
