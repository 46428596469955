import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db } from "../../firebase/config";
import useIsAdmin from "../../firebase/fbQueries";
import { faEarthAmerica, faLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function VideoCard(props) {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin(db);

  return (
    <>
      {(props.show || isAdmin) && (
        <div
          className="video-card"
          onClick={() => props.changeVideo(props.link)}
        >
          <div className="video-card-cover">
            {props.cover ? (
              <>
                <div
                  className="background"
                  style={{ backgroundColor: `#${props.color}` }}
                ></div>
                <img
                  src={props.cover}
                  alt={props.title}
                  className="video-cover-back"
                  onClick={props.handleVideo}
                  loading="lazy"
                />
                <img
                  src={props.cover}
                  alt={props.title}
                  className="video-cover"
                  onClick={props.handleVideo}
                  loading="lazy"
                />
              </>
            ) : (
              <>
                <div
                  className="background"
                  style={{ backgroundColor: `#${props.color}ab` }}
                >
                  <span className="title">• • •</span>
                </div>
              </>
            )}
          </div>
          <div className="video-card-title">
            <a href={props.link} target="_blank" rel="noopener noreferrer">
              <span
                className="video-title"
                title={`Daniel Ospid - ${props.title} (${props.format})`}
              >
                {`Daniel Ospid - ${props.title} (${props.format})`}
              </span>
            </a>
            <span className="video-date">
              {props.date &&
                new Date(props.date.seconds * 1000).toLocaleDateString(
                  t("lang.now"),
                  {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}
              {isAdmin &&
                (props.show ? (
                  <>
                    {" - Publico "}
                    <FontAwesomeIcon
                      icon={faEarthAmerica}
                      size="sm"
                      title="Publico"
                    />
                  </>
                ) : (
                  <>
                    {" - Privado "}
                    <FontAwesomeIcon icon={faLock} size="sm" title="Privado" />
                  </>
                ))}
            </span>
          </div>
        </div>
      )}
    </>
  );
}
export default VideoCard;
