import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen, faSearch } from "@fortawesome/free-solid-svg-icons";
import useIsAdmin, { useLyrics } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { useState } from "react";
import { Link } from "react-router-dom";

function Lyrics() {
  const isAdmin = useIsAdmin(db);
  const { t } = useTranslation();
  const lyrics = useLyrics(db);

  const [searchTerm, setSearchTerm] = useState("");

  const sameTitleLyric = lyrics.filter((lyric) => {
    const matchesSearch = lyric.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return matchesSearch;
  });

  return (
    <>
      <Helmet>
        <title>
          {t("title.lyrics")} - {website_name}
        </title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>{t("title.lyrics")}</h2>
            <div className="flex-action">
              {false && (
                <button
                  type="button"
                  className="button icon-button"
                  title="Agregar nueva letra"
                  aria-label="Agregar nueva letra"
                >
                  <FontAwesomeIcon icon={faFilePen} size="xl" />
                </button>
              )}
              <div className="select-wrapper">
                <input
                  type="text"
                  placeholder={t("music.label.search")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} size="sm" color="#757575" />
              </div>
            </div>
          </div>
          <table>
            <thead>
              <th>Título</th>
              <th>Álbum</th>
              <th>Fecha</th>
            </thead>
            <tbody>
              {sameTitleLyric
                .sort((a, b) => b.date - a.date)
                .map((lyric, index) => (
                  <>
                    {(lyric.show || isAdmin) && (
                      <tr key={index}>
                        <td>
                          {lyric.lock ? (
                            "Muy Pronto"
                          ) : (
                            <Link to={`/lyrics/${lyric.path}`}>
                              {lyric.title}
                            </Link>
                          )}
                        </td>
                        <td>
                          {lyric.lock
                            ? "Muy Pronto"
                            : lyric.album && lyric.album}
                        </td>
                        <td>
                          {lyric.date &&
                            new Date(
                              lyric.date.seconds * 1000
                            ).toLocaleDateString("es", {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            })}
                        </td>
                      </tr>
                    )}
                  </>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
export default Lyrics;
