import { Helmet } from "react-helmet";
import { Link, Outlet } from "react-router-dom";

function Vídeos() {
  return (
    <>
      <Helmet>
        <title>Vídeos - Panel de Control</title>
      </Helmet>
      
      <>
        <div className="admin-header">
          <div className="flex">
            <div className="title">Vídeos</div>
          </div>
          <div className="flex">
            <Link to="/admin/videos">Inicio</Link> /
            <Link to="/admin/videos/create">Crear</Link> /
            <Link to="/admin/videos/update">Actualizar</Link> /
            <Link to="/admin/videos/delete">Eliminar</Link>
          </div>
        </div>
        <div className="admin-body">
          <Outlet />
        </div>
      </>
    </>
  );
}
export default Vídeos;
